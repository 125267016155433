<template>
  <el-container class="main-container">
    <el-aside width="auto">
      <!-- 侧边栏 -->
      <common-aside />
    </el-aside>
    <el-container>
      <el-header>
        <!-- 头部 -->
        <common-header />
      </el-header>
      <div class="content-container">
        <common-tag />
        <el-main>
          <!---路由出口 -->
          <!---路由匹配到的组件将渲染在这里 -->
          <router-view />
          <!-- 添加 "个人中心" 对话框 -->
          <UserDetailDialog
            :dialogVisible="userProfileDialogVisible"
          ></UserDetailDialog>
          <UpdatePasswordDialog
            :dialogVisible="updatePasswordDialogVisible"
          ></UpdatePasswordDialog>
        </el-main>
      </div>
    </el-container>
  </el-container>
</template>
<script>
import commonAside from "../components/CommonAside.vue";
import CommonHeader from "@/components/CommonHeader.vue";
import CommonTag from "@/components/CommonTag.vue";
import UserDetailDialog from "@/components/UserDetailDialog.vue";
import UpdatePasswordDialog from "@/components/UpdatePasswordDialog.vue";
export default {
  data() {
    return {};
  },
  computed: {
    userProfileDialogVisible() {
      return this.$store.state.userProfileDialogVisible;
    },
    updatePasswordDialogVisible() {
      return this.$store.state.updatePasswordDialogVisible;
    },
  },
  components: {
    commonAside,
    CommonHeader,
    CommonTag,
    UserDetailDialog,
    UpdatePasswordDialog,
  },
};
</script>
<style lang="less" scoped>
.main-container {
  height: 100vh;
  .el-aside {
    background-color: #545c64;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .el-header {
    background-color: #fff;
    padding: 0;
    border-bottom: 1px solid #e6e6e6;
  }
  .content-container {
    height: calc(100vh - 60px); // 减去header的高度
    display: flex;
    flex-direction: column;
    background-color: #f0f2f5;
    overflow: hidden;
    
    .el-main {
      flex: 1;
      padding: 20px;
      overflow-y: auto;
      height: 100%;
    }
  }
}
</style>
