<template>
  <el-dialog
    :visible.sync="UserListDialogVisible"
    title="选择用户"
    :before-close="beforeClose"
    class="userListDialogClass"
    destroy-on-close
    center
  >
    <el-card>
      <el-input
        v-model="searchParam.name"
        placeholder="请输入用户名称"
        class="searchInput"
      ></el-input>
      <el-button type="primary" @click="searchUserList">搜索</el-button>
      <el-table
        class="UserListTableClass"
        :data="UserList"
        size="small"
        height="250"
      >
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="phone" label="手机号码"></el-table-column>
        <!-- areaName+address -->
        <el-table-column label="地址" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.areaName + scope.row.address }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="addTime" label="创建时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleChoose(scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pager"
        background
        layout="prev, pager, next,jumper, ->, total, slot"
        :page-size="pageData.pageSize"
        :total="total"
        @current-change="handlePage"
        :current-page.sync="currentPage"
        style="text-align: center"
      >
      </el-pagination>
    </el-card>
  </el-dialog>
</template>

<script>
import { getUser } from "../api";
export default {
  props: {
    dialogVisible: Boolean,
    user: Array,
  },
  methods: {
    handlePage(val) {
      this.pageData.pageNum = val;
      this.getUserList();
    },
    beforeClose(done) {
      this.$store.commit("updateUserListDialogVisibility", false);
      this.searchParam.name = null;
      done();
    },
    getUserList() {
      getUser({ params: { ...this.pageData, ...this.searchParam } }).then(
        (res) => {
          console.log(res, "getUserList");
          if (res.data.code === 200) {
            this.UserList = res.data.data;
            this.total = res.data.total;
          } else {
            this.$message.error(res.data.msg);
          }
        }
      );
    },
    searchUserList() {
      this.pageData.pageNum = 1;
      this.getUserList();
    },
    handleChoose(row) {
      this.$emit("chooseUser", row);
      this.$store.commit("updateUserListDialogVisibility", false);
    },
  },
  watch: {
    dialogVisible(newValue) {
      this.UserListDialogVisible = newValue;
      console.log(this.UserListDialogVisible, "UserListDialogVisible");
      if (this.UserListDialogVisible) {
        this.getUserList();
      }
    },
  },
  data() {
    return {
      UserListDialogVisible: this.dialogVisible,
      UserList: [],
      pageData: {
        pageSize: 10,
        pageNum: 1,
      },
      searchParam: {
        name: "",
      },
      currentPage: 1,
      total: 0,
    };
  },
};
</script>

<style lang="less" scoped>
.searchInput {
  width: 200px;
  margin-right: 10px;
}
.UserListTableClass {
  width: 100%;
  max-height: 500px;
}
.pager {
  margin-top: 20px;
}
</style>
