export default {
  methods: {
    hasPermission(permission) {
      // 1. 从路由 meta 获取权限
      const meta = this.$route.meta || {};
      let permissions = meta.permissions || [];

      // 2. 如果路由 meta 中没有权限，从 localStorage 获取
      if (!permissions.length) {
        const routePermissions = JSON.parse(
          localStorage.getItem("routePermissions") || "{}"
        );
        permissions = routePermissions[this.$route.path] || [];
      }

      return permissions.includes(permission);
    }
  }
};
