<template>
  <div class="manage">
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <!-- 用户的表单信息 -->
      <el-form
        ref="form"
        :rules="rules"
        :inline="true"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="姓名" prop="name">
          <el-input placeholder="请输入姓名" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="form.sex" placeholder="请选择">
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input
            placeholder="请输入手机号码"
            v-model="form.phone"
            :disabled="
              this.form.role === 1 && this.modalType === 1 ? true : false
            "
          ></el-input>
        </el-form-item>
        <!-- 编辑的情况下 如果当前角色为管理员 则不能更改角色 -->
        <el-form-item label="角色" prop="role">
          <el-select
            v-model="form.role"
            placeholder="请选择"
            :disabled="
              this.form.role === 1 && this.modalType === 1 ? true : false
            "
          >
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="省" prop="province">
          <el-select v-model="form.province" placeholder="请选择省">
            <el-option
              v-for="item in provinceList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option
          ></el-select>
        </el-form-item>
        <el-form-item label="市" prop="city">
          <el-select v-model="form.city" placeholder="请选择市">
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地区" prop="area">
          <el-select v-model="form.area" placeholder="请选择地区">
            <el-option
              v-for="item in areaList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option
          ></el-select>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input placeholder="请输入地址" v-model="form.address"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <div class="manage-header">
      <el-button @click="handleAdd" type="primary">+ 新增</el-button>
      <!-- 搜索区域 -->
      <el-form :model="userForm" :inline="true">
        <el-form-item>
          <el-input placeholder="请输入名称" v-model="userForm.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查 询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 列表 -->
    <div class="common-table">
      <el-table
        highlight-current-row
        stripe
        height="90%"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="sex" label="性别">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.sex == 1 ? "男" : "女"
            }}</span>
          </template>
        </el-table-column>
        <!--如果手机号码为空 显示为暂无数据 -->
        <el-table-column prop="phone" label="手机号码">
          <template slot-scope="scope">
            <span>{{ scope.row.phone ? scope.row.phone : "暂无" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="role" label="角色">
          <template slot-scope="scope">
            <!-- 获取用户角色方法 getRoleName -->
            <span>{{ getRoleName(scope.row.role) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="province" label="省市区">
          <template slot-scope="scope">
            <span>{{ scope.row.areaName ? scope.row.areaName : "暂无" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="地址">
          <template slot-scope="scope">
            <span>{{ scope.row.address ? scope.row.address : "暂无" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="addTime" label="创建时间"></el-table-column>
        <!-- 用户头像 -->
        <!-- 如果为空 展示 https://threeapple.cn/default.png -->
        <!-- <el-table-column prop="avatar" label="用户头像">
          <template slot-scope="scope">
            <img
              :src="
                scope.row.avatar
                  ? scope.row.avatar
                  : 'https://threeapple.cn/default.png'
              "
              alt=""
              style="width: 50px; height: 50px"
            />
          </template>
        </el-table-column> -->
        <el-table-column min-width="100">
          <template slot-scope="scope">
            <div class="buttonList">
              <el-button
                type="primary"
                size="mini"
                v-if="hasPermission('handleEdit')"
                @click="handleEdit(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="mini"
                v-if="hasPermission('handleDelete') && scope.row.id != 1"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
              <el-dropdown
                trigger="click"
                class="more-dropdown"
                v-if="scope.row.id != 1"
              >
                <el-button type="primary" size="mini">更多</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button
                      plain
                      type="success"
                      size="mini"
                      @click="showSubordinateUserList(scope.row)"
                    >
                      下级商家信息
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      plain
                      type="success"
                      size="mini"
                      v-if="hasPermission('showDetail') && scope.row.id != 1"
                      @click="account(scope.row)"
                      >收款信息</el-button
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pager"
        background
        layout="prev, pager, next,jumper, ->, total, slot"
        :page-size="pageData.pageSize"
        :total="total"
        @current-change="handlePage"
        :current-page.sync="currentPage"
        style="text-align: center"
      >
      </el-pagination>
    </div>
    <el-dialog
      class="subordinateUserMsgClass"
      :visible.sync="subordinateUserMsgVisible"
      title="下级商家信息"
      width="50%"
      destroy-on-close
    >
      <el-button
        class="addSubordinateUserClass"
        @click="addSubordinateUserButton"
        type="primary"
        size="small"
        >新增</el-button
      >
      <el-table
        :data="subordinateUserList"
        style="width: 100%"
        height="250"
        class="subordinateUserClass"
        size="small"
      >
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="phone" label="手机号码"></el-table-column>
        <!-- <el-table-column prop="role" label="角色"></el-table-column> -->
        <el-table-column prop="address" label="地址"></el-table-column>
        <el-table-column prop="addTime" label="创建时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="saveUserRelationButton(scope.row)"
              v-if="!scope.row.status"
              >保存</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="deleteUserRelationButton(scope.row)"
              v-if="scope.row.status"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <userListDialog
      :dialogVisible="UserListDialogVisible"
      :user="user"
      @chooseUser="handleUserChosen"
    ></userListDialog>
  </div>
</template>

<script>
import UserListDialog from "@/components/UserListDialog.vue";
import permissionMixin from "../mixins/permission";
import qs from "qs";
import {
  addUser,
  deleteVideAbleUser,
  delUser,
  editUser,
  getAreaList,
  getRoleList,
  getUser,
  getViewAbleUserList,
  saveUserRelation,
} from "../api";
export default {
  mixins: [permissionMixin],
  components: {
    UserListDialog,
  },
  computed: {
    UserListDialogVisible() {
      return this.$store.state.UserListDialogVisible;
    },
  },
  data() {
    return {
      dialogVisible: false,
      subordinateUserMsgVisible: false,
      subordinateUserList: [], //下级商家信息
      user: [],
      currentControlUser: [],
      saveUserRelationMsg: {
        userId: null,
        viewAbleUser: null,
      },
      form: {
        name: null,
        sex: null,
        phone: null,
        role: null,
        address: null,
        avatar: null,
        addTime: null,
        province: null,
        city: null,
        area: null,
      },
      provinceList: [], //省级信息
      cityList: [], //市级信息
      areaList: [], //地区信息
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        age: [
          {
            required: true,
            message: "请输入年龄",
            trigger: "blur",
          },
        ],
        //电话
        phone: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { min: 11, max: 11, message: "请输入11位电话号码", trigger: "blur" },
        ],
        birth: [
          { required: true, message: "请选择出生日期", trigger: "change" },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        province: [{ required: true, message: "请选择省", trigger: "blur" }],
        city: [{ required: true, message: "请选择市", trigger: "blur" }],
        area: [{ required: true, message: "请选择地区", trigger: "blur" }],
        role: [{ required: true, message: "请选择角色", trigger: "change" }],
      },
      tableData: [],
      modalType: 0, //0新增 1编辑
      total: 0, //总条数
      pageData: {
        pageNum: 1,
        pageSize: 10,
      },
      currentPage: 1,
      userForm: {
        name: null,
      },
      roleList: [],
    };
  },
  methods: {
    getRoleName(roleId) {
      //1 管理员 2 普通用户 3安卓更新 4 设备测试
      switch (roleId) {
        case 1:
          return "管理员";
        case 2:
          return "普通用户";
        case 3:
          return "安卓更新";
        case 4:
          return "设备测试";
        default:
          return "未知";
      }
    },
    handleUserChosen(user) {
      //如果subordinateUserList 中有user 则不添加
      if (this.subordinateUserList.find((item) => item.id === user.id)) {
        //提示
        this.$message({
          type: "error",
          message: "已经添加过该用户",
        });
        return;
      }
      // 将status 设置为null 便于和之前的区分
      user.status = null;
      // 将user添加到this.subordinateUserList 排在第一个
      this.subordinateUserList.push(user);
    },
    showSubordinateUserList(row) {
      console.log("下级商家信息");
      this.subordinateUserMsgVisible = true;
      this.currentControlUser = row;
      console.log(this.currentControlUser.id, "currentControlUser");
      this.getViewAbleUserLIst();
    },
    //获取下级商家信息
    getViewAbleUserLIst() {
      console.log(this.currentControlUser.id, "currentControlUserId");
      getViewAbleUserList({
        params: { userId: this.currentControlUser.id },
      }).then((res) => {
        console.log(res, "res");
        if (res.data.code === 200) {
          this.subordinateUserList = res.data.data;
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    addSubordinateUserButton() {
      console.log("新增下级商家");
      this.$store.commit("updateUserListDialogVisibility", true);
    },
    saveUserRelationButton(row) {
      this.saveUserRelationMsg.userId = this.currentControlUser.id;
      this.saveUserRelationMsg.viewAbleUser = row.id;
      this.saveUserRelationMethod();
    },
    deleteUserRelationButton(row) {
      //确定要删除吗
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.saveUserRelationMsg.userId = this.currentControlUser.id;
        this.saveUserRelationMsg.viewAbleUser = row.id;
        deleteVideAbleUser({
          params: {
            userId: this.saveUserRelationMsg.userId,
            viewAbleUserId: this.saveUserRelationMsg.viewAbleUser,
          },
        }).then((response) => {
          if (response.data.code === 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            //重新获取下级商家信息
            this.getViewAbleUserLIst();
          } else {
            this.$message({
              type: "error",
              message: response.data.message,
            });
          }
        });
      });
    },
    saveUserRelationMethod() {
      saveUserRelation(this.saveUserRelationMsg).then((response) => {
        if (response.data.code === 200) {
          this.$message({
            type: "success",
            message: "添加成功!",
          });
          this.getViewAbleUserLIst();
        } else {
          this.$message({
            type: "error",
            message: response.data.message,
          });
        }
      });
    },

    // 点击新增按钮
    submit() {
      this.$refs.form.validate((valid) => {
        console.log(this.form, "form");
        if (valid) {
          if (this.modalType === 0) {
            addUser(this.form).then((response) => {
              if (response.data.code === 200) {
                this.$message({
                  type: "success",
                  message: "添加成功!",
                });
              } else {
                this.$message({
                  type: "error",
                  message: response.data.message,
                });
              }
              this.getList();
            });
          } else {
            editUser(this.form).then((response) => {
              if (response.data.code === 200) {
                this.$message({
                  type: "success",
                  message: "编辑成功!",
                });
              } else {
                this.$message({
                  type: "error",
                  message: response.data.message,
                });
              }
              this.getList();
            });
          }
          console.log(valid, "valid");
          //后续表单处理
          //清空表单数据
          this.$refs.form.resetFields();
          //关闭弹窗
          this.dialogVisible = false;
        } else {
          this.$message({
            type: "error",
            message: "提交失败",
          });
          return false;
        }
      });
    },
    //弹窗关闭
    handleClose() {
      this.$refs.form.resetFields();
      // 手动关闭对话框
      this.dialogVisible = false;
    },
    cancel() {
      this.handleClose();
      this.dialogVisible = false;
    },
    //点击编辑按钮
    handleEdit(row) {
      this.modalType = 1;
      this.dialogVisible = true;
      //需要对当前行数据进行深拷贝,否则会出错
      this.form = JSON.parse(JSON.stringify(row));
      //获取省级信息
      getAreaList({ params: { level: 1 } }).then((res) => {
        console.log(res, "res");
        if (res.data.code === 200) {
          this.provinceList = res.data.data;
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
      //市级
      getAreaList({ params: { level: 2, pid: this.form.province } }).then(
        (res) => {
          console.log(res, "res");
          if (res.data.code === 200) {
            this.cityList = res.data.data;
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        }
      );
      //地区
      getAreaList({ params: { level: 3, pid: this.form.city } }).then((res) => {
        console.log(res, "res");
        if (res.data.code === 200) {
          this.areaList = res.data.data;
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    //点击删除按钮
    handleDelete(row) {
      const userId = row.id;
      const params = qs.stringify({ id: userId });
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delUser("web/user/delUser", params)
          .then((response) => {
            if (response.data.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            } else {
              this.$message({
                type: "error",
                message: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
          });
      });
    },
    //点击收款信息
    account(row) {
      this.$router.push({
        path: "/account",
        query: {
          id: row.id,
        },
      });
    },
    handleAdd() {
      this.form = {};
      this.dialogVisible = true;
      this.modalType = 0;
      //获取省级信息
      getAreaList({ params: { level: 1 } }).then((res) => {
        console.log(res, "res");
        if (res.data.code === 200) {
          this.provinceList = res.data.data;
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    getList() {
      if (this.userForm.name === "") {
        this.userForm.name = null;
      }
      getUser({ params: { ...this.userForm, ...this.pageData } }).then(
        (data) => {
          console.log(data, "data");
          console.log(data.data.total, "total");
          this.tableData = data.data.data;
          this.total = data.data.total ? data.data.total : 0;
          console.log(this.total, "total");
        }
      );
    },
    //选择页码回调函数
    handlePage(pageNum) {
      console.log(pageNum, "pageNum");
      this.pageData.pageNum = pageNum;
      this.getList();
    },
    onSubmit() {
      this.pageData.pageNum == 1;
      this.currentPage = 1;
      this.getList();
    },
  },
  mounted() {
    this.getList();
    //获取角色列表
    getRoleList().then((res) => {
      console.log(res, "res");
      if (res.data.code === 200) {
        this.roleList = res.data.data;
      }
    });
  },
  watch: {
    "form.province": {
      handler: function (newVal) {
        console.log(newVal, "newVal");
        getAreaList({ params: { level: 2, pid: newVal } }).then((res) => {
          console.log(res, "res");
          if (res.data.code === 200) {
            //清空市级信息
            this.cityList = res.data.data;
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
      },
    },
    "form.city": {
      handler: function (newVal) {
        console.log(newVal, "newVal");
        getAreaList({ params: { level: 3, pid: newVal } }).then((res) => {
          console.log(res, "res");
          if (res.data.code === 200) {
            this.areaList = res.data.data;
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
      },
    },
  },
};
</script>
<style lang="less" scoped>
.manage {
  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .manage-title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  height: 90%;
  .common-table {
    position: relative;
    height: calc(100% - 62px);
    .pager {
      position: absolute;
      bottom: 0;
      right: 20px;
    }
  }
  .buttonList {
    display: flex;
    margin-right: 10px;
  }

  .more-dropdown {
    margin-left: 8px; /* 将更多按钮放置到右侧 */
  }
  .subordinateUserMsgClass {
    .addSubordinateUserClass {
      margin-bottom: 10px;
      //靠在右边
      float: right;
      margin-right: 20px;
    }
  }
}
</style>
