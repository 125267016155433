<template>
  <div class="navigation-container">
    <el-tag
      v-for="(item, index) in tags"
      :key="item.path"
      :closable="item.path !== '/home'"
      :effect="$route.path === item.path ? 'dark' : 'plain'"
      @click="handleClick(item)"
      @close="handleClose(item, index)"
      size="small"
      class="nav-tag"
    >
      <span class="tag-content">
        <span v-if="index > 0" class="separator">/</span>
        {{ item.label }}
      </span>
    </el-tag>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "CommonTag",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      tags: (state) => state.tab.tabsList,
    }),
  },
  methods: {
    ...mapMutations(["closeTag"]),
    handleClick(item) {
      if (this.$route.path !== item.path) {
        this.$router.push(item.path);
        window.localStorage.setItem("activeMenu", item.path);
        this.$store.commit("selectMenu", item);
      }
    },
    handleClose(item) {
      // 如果关闭的是当前页，需要跳转到其他页面
      const isCurrent = this.$route.path === item.path;

      // 调用 store 中的 closeTag mutation
      this.closeTag(item);

      if (isCurrent) {
        // 如果关闭的是当前标签，跳转到最后一个标签
        const lastTag = this.tags[this.tags.length - 1];
        if (lastTag) {
          this.$router.push(lastTag.path);
          window.localStorage.setItem("activeMenu", lastTag.path);
          this.$store.commit("selectMenu", lastTag);
        }
      }
    },
  },
  mounted() {
    // 初始化时同步当前路由到菜单选中状态
    const currentPath = this.$route.path;
    const currentMenu = this.tags.find((item) => item.path === currentPath);
    if (currentMenu) {
      window.localStorage.setItem("activeMenu", currentPath);
      this.$store.commit("selectMenu", currentMenu);
    }
  },
};
</script>

<style lang="less" scoped>
.navigation-container {
  padding: 8px 20px;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  white-space: nowrap;
  overflow-x: auto;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  // 隐藏滚动条但保持功能
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  .nav-tag {
    position: relative;
    margin-right: 4px;
    cursor: pointer;
    user-select: none;

    &:last-child {
      margin-right: 0;
    }

    .tag-content {
      display: inline-flex;
      align-items: center;

      .separator {
        color: #909399;
        margin: 0 4px;
        font-size: 12px;
      }
    }

    &.el-tag--dark {
      background-color: #409eff;
      border-color: #409eff;

      .el-tag__close {
        color: #fff;

        &:hover {
          background-color: #66b1ff;
        }
      }

      .separator {
        color: #fff;
      }
    }

    &.el-tag--plain {
      &:hover {
        background-color: #ecf5ff;
      }

      .el-tag__close {
        &:hover {
          color: #fff;
          background-color: #409eff;
        }
      }
    }
  }
}
</style>
