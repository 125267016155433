import Vue from "vue";
import Vuex from "vuex";
import tab from "./tab";

Vue.use(Vuex);

//创建vuex对象
export default new Vuex.Store({
    modules: {
        //模块
        tab
    },
        state: {
      // 这里定义你的状态
            userProfileDialogVisible: false,
            updatePasswordDialogVisible: false,
            UserListDialogVisible: false,
            EquipmentDialogVisible: false,

      // 其他状态...
    },
    mutations: {
      // 这里定义你的 mutations
      updateUserProfileDialogVisibility(state, isVisible) {
        state.userProfileDialogVisible = isVisible;
        },
        updateUpdatePasswordDialogVisibility(state, isVisible) {
            state.updatePasswordDialogVisible = isVisible;
        },
        updateUserListDialogVisibility(state, isVisible) {
            state.UserListDialogVisible = isVisible;
        },
        updateEquipmentDialogVisibility(state, isVisible) {
            state.EquipmentDialogVisible = isVisible;
        }
      // 其他 mutations...
    },
    actions: {
      // 这里定义你的 actions
      // ...
    },

}
)
