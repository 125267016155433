<template>
  <div id="app">
    <!-- Circle selectors -->
    <div class="circle-selector">
      <div
        v-for="(machine, index) in machines"
        :key="index"
        class="circle"
        :class="{ selected: selectedMachine === machine.path }"
        @click="selectMachine(machine.path)"
      >
        {{ machine.label }}
      </div>
    </div>

    <!-- APK Upload -->
    <div class="upload-container">
      <label for="apk-upload" class="upload-btn-wrapper">
        <input
          type="file"
          id="apk-upload"
          accept=".apk"
          @change="handleApkFileUpload"
        />
        <div class="upload-btn">
          <span>点击上传APK文件</span>
        </div>
      </label>
    </div>

    <!-- JSON Upload -->
    <div class="upload-container">
      <label for="json-upload" class="upload-btn-wrapper">
        <input
          type="file"
          id="json-upload"
          accept=".json"
          @change="handleJsonFileUpload"
        />
        <div class="upload-btn">
          <span>点击上传JSON文件</span>
        </div>
      </label>
    </div>

    <!-- Loading Spinner -->
    <div v-if="isUploading" class="loading-spinner">正在上传...</div>
  </div>
</template>

<script>
import { fileUpload } from "../api";

export default {
  data() {
    return {
      isUploading: false,
      selectedMachine: null,
      machines: [
        { label: "二代机", path: "second-gen" },
        { label: "三代机", path: "third-gen" },
        { label: "冰淇淋", path: "ice-cream" },
        { label: "安卓", path: "android" },
      ],
    };
  },
  methods: {
    async handleApkFileUpload(event) {
      console.log(`上传APK文件:`, event.target.files[0]);
      await this.handleFileUpload(
        event,
        "application/vnd.android.package-archive"
      );
      event.target.value = ""; // 清除文件选择器中的文件
    },
    async handleJsonFileUpload(event) {
      console.log(`上传JSON文件:`, event.target.files[0]);
      await this.handleFileUpload(event, "application/json");
      event.target.value = ""; // 清除文件选择器中的文件
    },
    async handleFileUpload(event, fileType) {
      console.log(`handleFileUpload: ${fileType}`);
      if (this.isUploading) return;
      console.log(`handleFileUpload: ${fileType}`, event.target.files[0]);
      const file = event.target.files[0];
      console.log(`handleFileUpload: ${fileType}`, file);
      if (file && file.type === fileType) {
        if (!this.selectedMachine) {
          alert("请先选择机器类型");
          return; // 终止文件上传流程
        }
        console.log(`handleFileUpload: ${fileType}`, file);
        this.isUploading = true;
        console.log(
          `上传${
            fileType === "application/vnd.android.package-archive"
              ? "APK"
              : "JSON"
          }文件:`,
          file
        );

        try {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("urlPath", this.selectedMachine);

          await this.simulateUploadDelay();

          const response = await fileUpload(formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          if (response.data.code === 200) {
            this.$message.success("文件上传成功");
          } else {
            this.$message.error(response.data.message);
          }
        } catch (error) {
          console.error("文件上传失败", error);
          this.$message.error("文件上传失败");
        } finally {
          this.isUploading = false;
        }
      } else {
        alert(
          `请选择有效的${
            fileType === "application/vnd.android.package-archive"
              ? "APK"
              : "JSON"
          }文件`
        );
      }
    },
    async simulateUploadDelay() {
      return new Promise((resolve) => setTimeout(resolve, 2000));
    },
    // 选中机器类型
    selectMachine(machine) {
      this.selectedMachine = machine;
    },
  },
};
</script>

<style scoped>
/* 页面整体布局 */
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-container {
  margin-top: 20px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/* 选中圈样式 */
.circle-selector {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.circle.selected {
  background-color: #4caf50;
}

/* 加载提示 */
.loading-spinner {
  text-align: center;
  margin-top: 20px;
  color: blue;
}

/* 成功模态框 */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #4caf50; /* Green background color */
  color: white; /* White text color */
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  margin: 15% auto;
}

.close {
  color: white;
  float: right;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: #ccc;
}

.close:focus {
  color: black;
}
</style>
