<template>
  <el-dialog
    title="选择设备"
    :visible.sync="localVisible"
    width="70%"
    :before-close="handleClose"
    append-to-body
  >
    <!-- 搜索区域 -->
    <div class="search-area">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item>
          <el-input
            v-model="searchForm.name"
            placeholder="请输入设备名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchForm.identification"
            placeholder="请输入设备唯一标识"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
          <el-button @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 设备列表 -->
    <el-table
      :data="deviceList"
      style="width: 100%"
      v-loading="loading"
      highlight-current-row
    >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column
        prop="name"
        label="设备名称"
        min-width="120"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="identification"
        label="设备编号"
        min-width="120"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="type" label="设备类型" width="100">
        <template slot-scope="scope">
          <span>{{ getDeviceType(scope.row.type) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="addressStr"
        label="省市区"
        min-width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="address"
        label="详细地址"
        min-width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="ownerName"
        label="商家名称"
        min-width="120"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="操作" width="100" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click.stop="handleSelect(scope.row)"
          >
            选择
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination-container">
      <el-pagination
        :current-page.sync="page.pageNum"
        :page-size="page.pageSize"
        :total="total"
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import { getEquipmentList, getProductCateList } from "@/api";

export default {
  name: "EquipmentDialog",
  props: {
    userId: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    localVisible: {
      get() {
        return this.$store.state.EquipmentDialogVisible;
      },
      set(value) {
        this.$store.commit("updateEquipmentDialogVisibility", value);
      },
    },
  },
  data() {
    return {
      searchForm: {
        name: "",
        identification: "",
      },
      deviceList: [],
      loading: false,
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      deviceTypes: [],
      userInfo: null,
    };
  },
  methods: {
    // 获取设备列表
    async getList() {
      this.loading = true;
      try {
        const params = {
          ...this.searchForm,
          ...this.page,
          owner: this.userId || this.userInfo?.id,
          isShowSelf: this.userId ? 1 : this.userInfo?.role === 1 ? 0 : 1,
        };
        const res = await getEquipmentList({ params });
        if (res.data.code === 200) {
          this.deviceList = res.data.data;
          this.total = res.data.total;
        } else {
          this.$message.error(res.data.message || "获取设备列表失败");
        }
      } catch (error) {
        console.error("获取设备列表失败:", error);
        this.$message.error("获取设备列表失败");
      } finally {
        this.loading = false;
      }
    },

    // 搜索
    handleSearch() {
      this.page.pageNum = 1;
      this.getList();
    },

    // 重置搜索
    resetSearch() {
      this.searchForm = {
        name: "",
        identification: "",
      };
      this.page.pageNum = 1;
      this.getList();
    },

    // 处理分页变化
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },

    // 处理选择
    handleSelect(row) {
      this.$emit("select", row);
      this.handleClose();
    },

    // 处理关闭
    handleClose() {
      this.$store.commit("updateEquipmentDialogVisibility", false);
      this.resetSearch();
    },

    // 获取设备类型文本
    getDeviceType(type) {
      const typeObj = this.deviceTypes.find((item) => item.value === type);
      return typeObj ? typeObj.label : "";
    },
  },
  watch: {
    localVisible(val) {
      if (val) {
        this.getList();
      }
    },
  },
  created() {
    // 获取当前登录用户信息
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));

    // 初始化设备类型列表
    getProductCateList().then((res) => {
      if (res.data.code === 200) {
        this.deviceTypes = res.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      } else {
        this.$message.error("获取设备类型列表失败");
      }
    });
  },
};
</script>

<style scoped>
.search-area {
  margin-bottom: 20px;
}

.pagination-container {
  margin-top: 20px;
  text-align: right;
}

.el-table {
  margin-top: 15px;
}

.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.el-form-item {
  margin-bottom: 0;
}
</style>
