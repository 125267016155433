import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
import store from './store'
import Cookies from 'js-cookie'
import "amfe-flexible/index"

// 解决重复点击导航路由报错
const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err;
  });
};

Vue.config.productionTip = false
Vue.use(ElementUI)
// 全局修改默认配置，点击空白处不能关闭弹窗
ElementUI.Dialog.props.closeOnClickModal.default = false

//添加全局前置导航守卫
router.beforeEach((to, from, next) => {
    //如果跳转wxPay页面和wxBefore.vue,不拦截
    if (to.name == 'wxPay' || to.name == 'wxPayBefore' ||to.name == 'login') {
        console.log('to.name', to.name)
        next()
    } else {    //判断token是否存在
        const token = Cookies.get('token')
        console.log("token",token)
        //token不存在 为登陆 跳转登陆页面
        if(!token && to.name !== 'login'){
            next({name:'login'})
        } else if (token && to.name === 'login') {
            //token存在 为登陆 跳转登陆页面
                next({name:'home'})
            }else{
                next()
            }
    }
}


)

new Vue({
    router,
    store,
    render: h => h(App),
    created() {
       store.commit('addMenu', router)
   }
}).$mount('#app')
