<template>
  <div class="operation-status-container">
    <!-- 搜索区域 -->
    <div class="search-area">
      <el-form :inline="true" :model="dataForm" class="search-form">
        <el-form-item>
          <el-input
            v-model="dataForm.equipmentName"
            placeholder="请选择设备"
            :readonly="true"
            clearable
            @click.native="openDeviceDialog"
          >
            <template slot="suffix">
              <i
                v-if="dataForm.equipmentName"
                class="el-icon-circle-close el-input__icon"
                @click.stop="clearDevice"
                style="cursor: pointer"
              ></i>
            </template>
            <template slot="append">
              <el-button
                icon="el-icon-office-building"
                @click.stop="openDeviceDialog"
              ></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
          <el-button @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 表格区域容器 -->
    <div class="table-container">
      <!-- 设备状态内容列表 -->
      <el-table
        :data="recordList"
        highlight-current-row
        stripe
        class="recordListClass"
      >
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <!-- 设备名称 -->
        <el-table-column
          prop="equipmentName"
          label="设备名称"
          show-overflow-tooltip
        ></el-table-column>
        <!-- 商家 -->
        <el-table-column
          prop="merchantName"
          label="商家"
          show-overflow-tooltip
        ></el-table-column>
        <!-- 运行状态 -->
        <el-table-column
          prop="operationCode"
          label="运行状态"
          show-overflow-tooltip
        ></el-table-column>
        <!-- 是否修复   -->
        <el-table-column prop="isRepair" label="是否修复" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.operationType !== 2"> - </span>
            <span v-else>
              {{ scope.row.isRepair === 1 ? "修复" : "未修复" }}
            </span>
          </template>
        </el-table-column>
        <!-- 添加时间   -->
        <el-table-column
          prop="addTime"
          label="添加时间"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>

      <!-- 分页信息 -->
      <div class="pagination-container">
        <el-pagination
          background
          layout="prev, pager, next,jumper, ->, total, slot"
          :total="recordListTotal"
          :page-size="pageData.pageSize"
          @current-change="handleRecordListPage"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 设备选择对话框 -->
    <equipment-dialog @select="handleDeviceSelect" />
  </div>
</template>

<script>
import { getEquipmentOperationRecord } from "../../api/";
import EquipmentDialog from "@/components/EquipmentDialog.vue";

export default {
  components: {
    EquipmentDialog,
  },
  computed: {
    showDeviceDialog: {
      get() {
        return this.$store.state.EquipmentDialogVisible;
      },
      set(value) {
        this.$store.commit("updateEquipmentDialogVisibility", value);
      },
    },
  },
  data() {
    return {
      recordList: [],
      recordListTotal: 0,
      pageData: {
        pageNum: 1,
        pageSize: 10,
      },
      dataForm: {
        identification: null,
        equipmentName: "",
      },
    };
  },
  methods: {
    // 获取设备状态记录列表
    getRecordList() {
      console.log(this.dataForm, "dataForm");
      getEquipmentOperationRecord({
        params: { ...this.pageData, ...this.dataForm },
      }).then((res) => {
        this.recordList = res.data.data;
        this.recordListTotal = res.data.total;
      });
    },

    // 分页
    handleRecordListPage(val) {
      this.pageData.pageNum = val;
      this.getRecordList();
    },

    // 打开设备选择对话框
    openDeviceDialog() {
      this.$store.commit("updateEquipmentDialogVisibility", true);
    },

    // 处理设备选择
    handleDeviceSelect(device) {
      this.dataForm.identification = device.id;
      this.dataForm.equipmentName = device.name;
      this.$store.commit("updateEquipmentDialogVisibility", false);
    },

    // 清空设备选择
    clearDevice() {
      this.dataForm.identification = null;
      this.dataForm.equipmentName = "";
    },

    // 搜索
    handleSearch() {
      this.pageData.pageNum = 1;
      this.getRecordList();
    },

    // 重置搜索
    resetSearch() {
      this.dataForm = {
        identification: null,
        equipmentName: "",
      };
      this.pageData.pageNum = 1;
      this.getRecordList();
    },
  },
  mounted() {
    this.getRecordList();
  },
};
</script>

<style lang="less" scoped>
.operation-status-container {
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.search-area {
  margin-bottom: 20px;
  flex-shrink: 0;
}

.table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
}

.recordListClass {
  height: 0;
  flex: 1;
  overflow: auto;
}

.pagination-container {
  margin-top: 20px;
  text-align: right;
  flex-shrink: 0;
}

.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.el-form-item {
  margin-bottom: 0;
}

:deep(.el-table__body-wrapper) {
  overflow-y: auto !important;
}

:deep(.el-table) {
  height: 100% !important;
}
</style>
