<template>
  <div class="home-container">
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="dashboard-stats">
          <el-card
            :body-style="{ display: 'flex', padding: '0' }"
            class="stat-card"
          >
            <i class="icon el-icon-success" style="background: #13ce66"></i>
            <div class="detail">
              <p class="price">{{ topData.userNum }} 位</p>
              <p class="desc">用户数量</p>
            </div>
          </el-card>
          <el-card
            :body-style="{ display: 'flex', padding: '0' }"
            class="stat-card"
          >
            <i class="icon el-icon-success" style="background: #ff7000"></i>
            <div class="detail">
              <p class="price">{{ topData.equipmentNum }} 台</p>
              <p class="desc">设备数量</p>
            </div>
          </el-card>
          <el-card
            :body-style="{ display: 'flex', padding: '0' }"
            class="stat-card"
          >
            <i class="icon el-icon-success" style="background: #0033cc"></i>
            <div class="detail">
              <p class="price">{{ topData.toDayBuyCount }} 个</p>
              <p class="desc">今日订单</p>
            </div>
          </el-card>
          <el-card
            :body-style="{ display: 'flex', padding: '0' }"
            class="stat-card"
          >
            <i class="icon el-icon-success" style="background: #13ce66"></i>
            <div class="detail">
              <p class="price">{{ topData.toDayBuyPrice / 100 }} 元</p>
              <p class="desc">今日营业额</p>
            </div>
          </el-card>
          <el-card
            :body-style="{ display: 'flex', padding: '0' }"
            class="stat-card"
          >
            <i class="icon el-icon-success" style="background: #ff7000"></i>
            <div class="detail">
              <p class="price">{{ topData.allBuyCount }} 个</p>
              <p class="desc">总订单</p>
            </div>
          </el-card>
          <el-card
            :body-style="{ display: 'flex', padding: '0' }"
            class="stat-card"
          >
            <i class="icon el-icon-success" style="background: #0033cc"></i>
            <div class="detail">
              <p class="price">{{ topData.allBuyPrice / 100 }} 元</p>
              <p class="desc">总营业额</p>
            </div>
          </el-card>
        </div>

        <div class="charts-container">
          <el-card class="chart-card">
            <div class="chart-header">
              <h3>销售趋势统计</h3>
              <el-radio-group
                v-model="timePeriod"
                size="small"
                @change="handlePeriodChange"
              >
                <el-radio-button label="day">日</el-radio-button>
                <el-radio-button label="month">月</el-radio-button>
                <el-radio-button label="year">年</el-radio-button>
              </el-radio-group>
            </div>
            <div ref="echarts1" class="chart"></div>
          </el-card>

          <div class="chart-row">
            <el-card class="chart-card">
              <h3>平台设备排行统计</h3>
              <div ref="echarts2" class="chart"></div>
            </el-card>
            <el-card class="chart-card">
              <h3>平台营业额排行统计</h3>
              <div ref="echarts3" class="chart"></div>
            </el-card>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getDataReportAdmin,
  getEquipmentRankingAdmin,
  getSalePriceRankingAdmin,
  getSaleRecordAdmin,
  getProductCateList,
} from "../api";
import * as echarts from "echarts";

export default {
  data() {
    return {
      timePeriod: "day",
      topData: {
        equipmentNum: 0,
        userNum: 0,
        toDayBuyCount: 0,
        toDayBuyPrice: 0,
        allBuyPrice: 0,
        allBuyCount: 0,
      },
      typeList: [],
      chartData: {
        times: [],
        salePrices: [],
        orderNums: [],
      },
    };
  },
  mounted() {
    //获取产品类型
    getProductCateList().then((res) => {
      if (res.data.code === 200) {
        this.typeList = res.data.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      } else {
        this.$message({
          message: "获取产品分类信息失败",
          type: "error",
        });
      }
      console.log("typeList", this.typeList);
    });
    getSaleRecordAdmin({ type: 1 }).then(({ data }) => {
      console.log("getSaleRecordAdmin", data);
      this.chartData.times = data.data.map((item) => item.time);
      this.chartData.salePrices = data.data.map((item) => item.salePrice / 100);
      this.chartData.orderNums = data.data.map((item) => item.orderNum);
      this.renderChart();
    });
    getEquipmentRankingAdmin().then(({ data }) => {
      console.log("getEquipmentRankingAdmin", data);
      //初始化echarts2实例
      const echarts2 = echarts.init(this.$refs.echarts2);
      //柱状图
      const echarts2Option = {
        legend: {
          // 图例文字颜色
          textStyle: {
            color: "#333",
          },
        },
        grid: {
          left: "20%",
        },
        // 提示框
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category", // 类目轴
          data: data.data.map((item) => item.username),
          axisLine: {
            lineStyle: {
              color: "#17b3a3",
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 45, // 旋转角度
            color: "#333",
            textStyle: {
              fontSize: 8, // 根据需要调整字体大小
            },
          },
        },
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLine: {
              lineStyle: {
                color: "#17b3a3",
              },
            },
          },
        ],
        color: ["#2ec7c9", "#b6a2de"],
        series: [
          {
            name: "用户名称",
            type: "bar",
            data: data.data.map((item) => item.username),
          },
          {
            name: "设备数量",
            type: "bar",
            data: data.data.map((item) => item.equipmentNum),
          },
        ],
      };
      echarts2.setOption(echarts2Option);
    });
    getSalePriceRankingAdmin().then(({ data }) => {
      console.log("getSalePriceRankingAdmin", data);
      //初始化echarts3实例
      const echarts3 = echarts.init(this.$refs.echarts3);
      //饼状图
      const echarts3Option = {
        legend: {
          // 图例文字颜色
          textStyle: {
            color: "#111",
          },
        },
        grid: {
          left: "20%",
        },
        // 提示框
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category", // 类目轴
          data: data.data.map((item) => item.username),
          axisLine: {
            lineStyle: {
              color: "#17b3a3",
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 45, // 旋转角度
            color: "#333",
            textStyle: {
              fontSize: 8, // 根据需要调整字体大小
            },
          },
        },
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLine: {
              lineStyle: {
                color: "#17b3a3",
              },
            },
          },
        ],
        color: ["#2ec7c9", "#b6a2de"],
        series: [
          {
            name: "用户名称",
            type: "bar",
            data: data.data.map((item) => item.username),
          },
          {
            name: "营业额(单位:元)",
            type: "bar",
            data: data.data.map((item) => item.salePrice / 100),
          },
        ],
      };
      echarts3.setOption(echarts3Option);
    });
    this.getData();
  },
  methods: {
    handlePeriodChange() {
      // 调用接口获取数据，默认type为1
      getSaleRecordAdmin({
        type:
          this.timePeriod === "day" ? 1 : this.timePeriod === "month" ? 2 : 3,
      }).then(({ data }) => {
        if (data.code === 200) {
          this.chartData.times = data.data.map((item) => item.time);
          this.chartData.salePrices = data.data.map(
            (item) => item.salePrice / 100
          );
          this.chartData.orderNums = data.data.map((item) => item.orderNum);
          this.renderChart();
        }
      });
    },

    renderChart() {
      const echarts1 = echarts.init(this.$refs.echarts1);
      const xAxisName = {
        day: "小时(单位:时)",
        month: "日期(单位:天)",
        year: "月份(单位:月)",
      }[this.timePeriod];

      const echarts1Option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          textStyle: {
            color: "#333",
          },
          data: ["营业额", "订单数"],
        },
        xAxis: {
          type: "category",
          name: xAxisName,
          data: this.chartData.times,
          axisLine: {
            lineStyle: {
              color: "#17b3a3",
            },
          },
          axisLabel: {
            interval: 0,
            color: "#333",
          },
        },
        yAxis: [
          {
            type: "value",
            name: "营业额(单位:元)",
            minInterval: 1,
            axisLine: {
              lineStyle: {
                color: "#17b3a3",
              },
            },
            axisLabel: {
              color: "#333",
            },
          },
        ],
        series: [
          {
            name: "营业额",
            type: "line",
            yAxisIndex: 0,
            data: this.chartData.salePrices,
            smooth: true,
          },
          {
            name: "订单数",
            type: "line",
            yAxisIndex: 0,
            data: this.chartData.orderNums,
            smooth: true,
          },
        ],
      };
      echarts1.setOption(echarts1Option);
    },
    getData() {
      getDataReportAdmin().then((data) => {
        console.log("topdata", data);
        if (data.data.code === 200) {
          this.topData = data.data.data;
        } else {
          this.$message.error(data.data.msg);
        }
      });
    },
    //获取产品类型
    getProductType(type) {
      //根据 typeList 动态获取
      let typeList = this.typeList;
      let typeObj = typeList.find((item) => {
        return item.value == type;
      });
      return typeObj ? typeObj.label : "";
    },
  },
};
</script>

<style lang="less" scoped>
.home-container {
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  background-color: #f0f2f5;

  .dashboard-stats {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    margin-bottom: 20px;

    .stat-card {
      height: 100px;
      transition: all 0.3s;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }

      .icon {
        width: 80px;
        height: 80px;
        margin: 10px;
        border-radius: 8px;
        font-size: 30px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .detail {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;

        .price {
          font-size: 20px;
          font-weight: bold;
          margin: 0;
          color: #303133;
        }

        .desc {
          font-size: 14px;
          color: #909399;
          margin: 5px 0 0 0;
        }
      }
    }
  }

  .charts-container {
    .chart-card {
      margin-bottom: 20px;

      .chart-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        margin-bottom: 20px;
      }

      .chart {
        height: 280px;
      }
    }

    .chart-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      .chart {
        height: 260px;
      }
    }
  }
}
</style>
