<template>
  <div class="manage">
    <el-dialog
      :title="modalType === 1 ? '修改设备' : '新增设备'"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <!-- 设备表单信息 -->
      <el-form
        ref="form"
        :rules="rules"
        :inline="true"
        size="small"
        :model="form"
      >
        <div class="form-section">
          <el-form-item label="设备编号" prop="identification">
            <el-input
              placeholder="请输入设备编号"
              v-model="form.identification"
              :disabled="this.modalType === 1 ? true : false"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备类型" prop="type">
            <el-select
              v-model="form.type"
              placeholder="请选择"
              :disabled="this.modalType === 1 ? true : false"
            >
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备名称" prop="name" show-overflow-tooltip>
            <el-input
              placeholder="请输入设备名称"
              v-model="form.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="在线状态" prop="onlineStatus">
            <el-select v-model="form.onlineStatus" placeholder="请选择">
              <el-option label="在线" :value="1"></el-option>
              <el-option label="离线" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户分类" prop="userCategory">
            <el-select v-model="form.userCategory" placeholder="请选择">
              <el-option label="家庭用户" :value="0"></el-option>
              <el-option label="商业用户" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备描述" prop="description">
            <el-input
              placeholder="请输入设备描述"
              v-model="form.description"
            ></el-input>
          </el-form-item>
          <el-form-item label="省" prop="province">
            <el-select v-model="form.province" placeholder="请选择省">
              <el-option
                v-for="item in provinceList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option
            ></el-select>
          </el-form-item>
          <el-form-item label="市" prop="city">
            <el-select v-model="form.city" placeholder="请选择市">
              <el-option
                v-for="item in cityList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地区" prop="area">
            <el-select v-model="form.area" placeholder="请选择地区">
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option
            ></el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input
              placeholder="请输入设备放置区域"
              v-model="form.address"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="商家名称"
            prop="owner"
            v-if="this.userInfo.id == 1"
          >
            <el-input
              v-model="form.ownerName"
              placeholder="请选择商家"
              readonly
              clearable
              @click.native="openSearchUserDialog"
              class="merchant-input"
            >
              <template slot="suffix">
                <i
                  v-if="form.ownerName"
                  class="el-icon-circle-close el-input__icon"
                  @click.stop="clearSearchUser"
                  style="cursor: pointer"
                ></i>
              </template>
              <template slot="append">
                <el-button
                  icon="el-icon-user"
                  @click.stop="openSearchUserDialog"
                ></el-button>
              </template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <div class="manage-header">
      <el-button
        v-if="hasPermission('handleAdd')"
        @click="handleAdd"
        type="primary"
        >+ 新增</el-button
      >
      <!-- 搜索区域 -->
      <el-form :model="dataForm" :inline="true">
        <el-form-item>
          <el-input
            placeholder="请输入设备名称"
            v-model="dataForm.name"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="请输入设备唯一标识"
            v-model="dataForm.identification"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="dataForm.isShowSelf"
            placeholder="是否只查看自己"
            v-if="userInfo.role != 1"
            clearable
          >
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <template v-if="this.userInfo.id == 1">
          <el-form-item>
            <el-input
              v-model="dataForm.searchUserName"
              placeholder="请选择商家"
              :readonly="true"
              clearable
              @click.native="openSearchUserDialog"
            >
              <template slot="suffix">
                <i
                  v-if="dataForm.searchUserName"
                  class="el-icon-circle-close el-input__icon"
                  @click.stop="clearSearchUser"
                  style="cursor: pointer"
                ></i>
              </template>
              <template slot="append">
                <el-button
                  icon="el-icon-user"
                  @click.stop="openSearchUserDialog"
                ></el-button>
              </template>
            </el-input>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查 询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="common-table">
      <el-table stripe :data="tableData" highlight-current-row>
        <el-table-column
          prop="name"
          label="设备名称"
          min-width="100px"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="identification"
          label="唯一标识"
          min-width="130px"
        >
        </el-table-column>
        <el-table-column prop="type" label="设备类型" min-width="100px">
          <template slot-scope="scope">
            <span style="margin-left: 10px">
              {{ getProductType(scope.row.type) }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="description"
          label="设备描述"
          min-width="100px"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="addressStr"
          label="省市区"
          show-overflow-tooltip
          width="200px"
        >
        </el-table-column>
        <el-table-column
          prop="address"
          label="放置区域"
          width="100px"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="ownerName"
          label="商家名称"
          min-width="100px"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="addTime"
          label="添加时间"
          show-overflow-tooltip
          min-width="100px"
        >
        </el-table-column>
        <el-table-column label="分账开关" min-width="80">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isDivide"
              active-color="#13ce66"
              inactive-color="#838383"
              :active-value="1"
              :inactive-value="0"
              @change="updateSystemSwitch(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="200">
          <template slot-scope="scope">
            <div class="operation-buttons">
              <el-button
                type="primary"
                size="mini"
                v-if="hasPermission('handleEdit')"
                @click="handleEdit(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="mini"
                v-if="hasPermission('handleDelete')"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
              <el-dropdown trigger="click">
                <el-button type="info" size="mini">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="hasPermission('productAlot')">
                    <span @click="productAlot(scope.row)">商品配置</span>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="hasPermission('IncomeDistribution')">
                    <span @click="IncomeDistribution(scope.row)">收益分配</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pager"
        background
        layout="prev, pager, next,jumper, ->, total, slot"
        :page-size="pageData.pageSize"
        :total="total"
        @current-change="handlePage"
        :current-page.sync="currentPage"
        style="text-align: center"
      >
      </el-pagination>
    </div>
    <!-- 机器商品配置 -->
    <div class="productAlotClass">
      <el-dialog
        title="产品配置"
        :visible.sync="productAlotDialog"
        width="50%"
        :before-close="productAlotDialogClose"
      >
        <el-form ref="form" :inline="true" size="small" :model="form">
          <el-form-item label="设备名称" prop="name" show-overflow-tooltip>
            <el-input
              placeholder="请输入设备名称"
              v-model="form.name"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="设备编号" prop="identification">
            <el-input
              placeholder="请输入设备编号"
              v-model="form.identification"
              disabled
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- 产品配置dialog -->
        <el-table
          stripe
          :data="equipmentProductList"
          highlight-current-row
          empty-text="暂无数据"
        >
          <el-table-column label="序号">
            <template slot-scope="scope">
              <el-button-group>
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-sort-up"
                  v-if="scope.$index != 0"
                  @click="moveUp(scope.$index)"
                />
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-sort-down"
                  v-if="scope.$index != equipmentProductList.length - 1"
                  @click="moveDown(scope.$index)"
                />
              </el-button-group>
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="productTypeName"
            label="产品类型"
          ></el-table-column>
          <el-table-column
            prop="flavorsName"
            label="产品口味"
          ></el-table-column>
          <el-table-column prop="sizeName" label="规格"></el-table-column>
          <el-table-column prop="price" label="产品价格">
            <template slot-scope="scope">
              <span v-if="!scope.row.editing"
                >{{ scope.row.price / 100 }} 元</span
              >
              <el-input
                size="small"
                v-else
                v-model="scope.row.price"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="显示名称" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="!scope.row.editing">{{ scope.row.name }}</span>
              <el-input size="small" v-else v-model="scope.row.name"></el-input>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="img" label="图片"></el-table-column> -->
          <el-table-column prop="status" label="上下架">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                active-color="#13ce66"
                inactive-color="#838383"
                :active-value="1"
                :inactive-value="0"
                @change="productUpOrDown(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="addTime"
            show-overflow-tooltip
            label="添加时间"
          ></el-table-column>
          <el-table-column label="操作" min-width="140">
            <template slot-scope="scope">
              <div class="productAllotButtonClass">
                <el-button
                  size="mini"
                  v-if="!scope.row.editing && scope.row.id != null"
                  @click="productEdit(scope.row)"
                >
                  修改
                </el-button>
                <el-button
                  size="mini"
                  v-else
                  type="primary"
                  @click="productSave(scope.row)"
                >
                  保存
                </el-button>
                <el-button
                  size="mini"
                  type="danger"
                  @click="productDelete(scope.row)"
                  >删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center; margin-top: 20px">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="addNewProduct"
            >添加</el-button
          >
        </div>
      </el-dialog>

      <!-- 产品列表选择对话框 -->
      <el-dialog
        title="选择产品"
        :visible.sync="addProductDialogVisible"
        width="50%"
        :before-close="productDialogClose"
        append-to-body
      >
        <el-form ref="form" :rules="rules" :model="searchProductForm" inline>
          <!-- 选择产品类型/口味/规格 在一行 -->
          <el-form-item label="产品类型" prop="productType">
            <el-select
              v-model="searchProductForm.productType"
              placeholder="请选择产品类型"
              clearable
              filterable
              size="small"
              @change="searchAddProductList"
            >
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品口味" prop="flavors">
            <el-select
              v-model="searchProductForm.flavors"
              placeholder="请选择产品口味"
              clearable
              size="small"
              filterable
              @change="searchAddProductList"
            >
              <el-option
                v-for="item in productTasteList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="规格" prop="size">
            <el-select
              v-model="searchProductForm.size"
              placeholder="请选择规格"
              clearable
              size="small"
              filterable
              @change="searchAddProductList"
            >
              <el-option
                v-for="item in juiceSizeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-table
          stripe
          :data="productList"
          highlight-current-row
          empty-text="暂无数据"
          size="mini"
        >
          <el-table-column prop="productType" label="产品类型">
          </el-table-column>
          <el-table-column prop="flavorName" label="产品口味">
          </el-table-column>
          <el-table-column prop="sizeName" label="规格"> </el-table-column>
          <el-table-column prop="price" label="产品价格">
            <template slot-scope="scope">
              {{ scope.row.price / 100 }}元
            </template>
          </el-table-column>
          <el-table-column
            prop="totalName"
            label="显示名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <!-- <el-table-column prop="img" label="图片" show-overflow-tooltip>
          </el-table-column> -->
          <el-table-column label="操作" min-width="85">
            <template slot-scope="scope">
              <div class="button-group">
                <el-button
                  type="primary"
                  size="mini"
                  @click="addProductClick(scope.row)"
                  >添加</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页信息 -->
        <el-pagination
          layout="prev, pager, next"
          :total="addProductListTotal"
          @current-change="handleProductListPage"
          class="ProductListPageClass"
        >
        </el-pagination>
      </el-dialog>
    </div>
    <div class="IncomeDistributionClass">
      <!-- 收益分配 -->
      <el-dialog
        title="收益分配"
        :visible.sync="IncomeDistributionDialogVisible"
        width="50%"
        :before-close="IncomeDistributionHandleClose"
      >
        <!-- 设备表单信息 -->
        <el-form ref="form" :inline="true" size="small" :model="form">
          <el-form-item label="设备名称" prop="name" show-overflow-tooltip>
            <el-input
              placeholder="请输入设备名称"
              v-model="form.name"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="设备编号" prop="identification">
            <el-input
              placeholder="请输入设备编号"
              v-model="form.identification"
              disabled
            ></el-input>
          </el-form-item>
        </el-form>
        <el-table
          stripe
          fullscreen
          destroy-on-close
          :data="IncomeRatioList"
          highlight-current-row
          empty-text="暂无数据,默认按照商家和公司五五分成"
        >
          <el-table-column
            prop="userName"
            label="商家名称"
            width="100px"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="ratio" label="收益比例" width="100px">
            <template slot-scope="scope">
              <span v-if="!scope.row.editable">{{ scope.row.ratio }}</span>
              <span v-else>
                <el-input size="small" v-model="scope.row.ratio"></el-input>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="addTime"
            label="添加时间"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="操作" min-width="85">
            <template slot-scope="scope">
              <div class="button-group">
                <el-button
                  type="danger"
                  size="mini"
                  @click="deleteIncomeDistribution(scope.row)"
                  >删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="IncomeDistributionHandleCancel">取 消</el-button>
          <el-button type="primary" @click="IncomeDistributionHandleSubmit"
            >保 存</el-button
          >
          <el-button
            type="primary"
            @click="editIncomeDistribution"
            :disabled="this.IncomeRatioList.length === 0"
            >编辑</el-button
          >
          <el-button
            type="primary"
            @click="addMerchant"
            :disabled="this.IncomeRatioList.length >= 5"
            >添加商家</el-button
          >
        </span>
      </el-dialog>
      <!-- 添加用户选择对话框 -->
      <user-list-dialog
        :dialogVisible="UserListDialogVisible"
        :user="user"
        @chooseUser="handleUserSelect"
      />
    </div>
  </div>
</template>
<script>
import qs from "qs";
import permissionMixin from "../mixins/permission";
import UserListDialog from "@/components/UserListDialog.vue";
import {
  addEquipment,
  addEquipmentProduct,
  delEquipment,
  delEquipmentProduct,
  editEquipment,
  getAreaList,
  getEquipmentList,
  getIncomeRatioListByEquipmentId,
  getProductCateList,
  getProductList,
  getUser,
  saveIncomeRatioList,
  selEquipmentProductList,
  updateEquipentProduct,
} from "../api/";
export default {
  components: {
    UserListDialog,
  },
  mixins: [permissionMixin],
  computed: {
    UserListDialogVisible: {
      get() {
        return this.$store.state.UserListDialogVisible;
      },
      set(value) {
        this.$store.commit("updateUserListDialogVisibility", value);
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      IncomeDistributionDialogVisible: false,
      choseMerchantDialogVisible: false,
      productAlotDialog: false, //产品配置dialog
      addProductDialogVisible: false, //产品列表dialog
      addProductListTotal: 0,
      modalType: 0, //0新增 1编辑'
      form: {
        name: "",
        type: "",
        juiceCategory: "",
        iceCreamFlavor: "",
        identification: "",
        userCategory: "",
        onlineStatus: "",
        // picture: "",
        description: "",
        province: "",
        city: "",
        area: "",
        owner: "",
        ownerName: "",
        juiceSize: "",
        address: "",
        addressStr: "***",
        isDivide: "",
        // addTime: "",
      },
      provinceList: [],
      cityList: [],
      areaList: [],
      searchProductForm: {
        productType: "",
        flavors: "",
        size: "",
        pageNum: 1,
        pageSize: 10,
      },
      //产品口味分类
      productTasteList: [],
      //设备配置的产品列表
      equipmentProductList: [],
      productList: [], //选择产品列表
      //产品分类
      typeList: [],
      userList: [
        {
          value: 1,
          label: "家庭用户",
        },
        {
          value: 2,
          label: "商业用户",
        },
      ],
      userInfo: {
        id: "",
      },
      //规格 (下拉选择)
      juiceSizeList: [
        {
          value: 1,
          label: "小杯",
        },
        {
          value: 2,
          label: "中杯",
        },
        {
          value: 3,
          label: "大杯",
        },
      ],
      //规格
      juiceSizeMap() {
        return {
          1: "小杯",
          2: "中杯",
          3: "大杯",
        };
      },
      //规格
      iceCreamSizeMap() {
        return {
          1: "小杯",
          2: "中杯",
          3: "大杯",
        };
      },
      tableData: [],
      //收益分配
      IncomeRatioList: [],
      editing: false,
      total: 0, //总条数
      pageData: {
        pageNum: 1,
        pageSize: 10,
      },
      currentPage: 1,
      searchAddr: [],
      dataForm: {
        name: null,
        owner: null,
        identification: null,
        searchUserId: null,
        searchUserName: "",
        province: null,
        city: null,
        area: null,
        isShowSelf: 0,
      },
      rules: {
        name: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        type: [{ required: true, message: "请选择设备类型", trigger: "blur" }],
        identification: [
          { required: true, message: "请输入设备编号", trigger: "blur" },
        ],
        juiceSize: [{ required: true, message: "请选择规格", trigger: "blur" }],
        // productTaste: [
        //   { required: true, message: "请选择产品口味", trigger: "blur" },
        // ],
        userCategory: [
          { required: true, message: "请选择用户分类", trigger: "blur" },
        ],
        onlineStatus: [
          { required: true, message: "请选择在线状态", trigger: "blur" },
        ],
        // picture: [
        //   { required: true, message: "请输入设备图片", trigger: "blur" },
        // ],
        description: [
          { required: true, message: "请输入设备描述", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入放置区域", trigger: "blur" },
        ],
        owner: [{ required: true, message: "请选择商家", trigger: "blur" }],
        province: [{ required: true, message: "请选择省", trigger: "blur" }],
        city: [{ required: true, message: "请选择市", trigger: "blur" }],
        area: [{ required: true, message: "请选择地区", trigger: "blur" }],
        ownerName: [{ required: true, message: "请选择商家", trigger: "blur" }],
      },
      user: null, //
    };
  },
  methods: {
    //删除已经添加的商家
    deleteIncomeDistribution(row) {
      this.IncomeRatioList = this.IncomeRatioList.filter((item) => {
        return item.userName !== row.userName;
      });
    },
    choseMerchantHandleClose() {
      this.choseMerchantDialogVisible = false;
    },
    //添加商家 获取用户列表
    addMerchant() {
      this.$store.commit("updateUserListDialogVisibility", true);
    },
    //取消添加商家弹窗
    cancelChoseMerchant() {
      this.choseMerchantDialogVisible = false;
    },
    //确定添加商家
    submitChoseMerchant() {
      //讲当前商家信息添加到收益分配列表
      //添加前校验是��已经添加过
      let isExist = this.IncomeRatioList.some((item) => {
        return (
          item.userName ==
          this.userList.find((item) => {
            return item.value == this.form.owner;
          }).label
        );
      });
      if (isExist) {
        this.$message({
          type: "error",
          message: "该商家已经添加",
        });
        return;
      }
      this.IncomeRatioList.push({
        userName: this.userList.find((item) => {
          return item.value == this.form.owner;
        }).label,
        userId: this.userList.find((item) => {
          return item.value == this.form.owner;
        }).value,
        ratio: "",
        editable: true,
      });
      this.choseMerchantDialogVisible = false;
    },
    //关闭收益分配弹窗
    IncomeDistributionHandleCancel() {
      this.editing = false;
      // Handle cancel action
      this.IncomeRatioList.forEach((row) => {
        console.log(row, "row");
        this.$set(row, "editable", false);
      });
      this.handleClose();
      this.IncomeDistributionDialogVisible = false;
    },
    IncomeDistributionHandleSubmit() {
      // Validate and save data
      if (this.validateIncomeDistribution()) {
        //批量保存分配比例信息
        this.IncomeRatioList.forEach((item) => {
          item.equipmentId = this.form.id;
        });
        console.log(this.IncomeRatioList, "IncomeRatioList");
        //保存收益比例
        saveIncomeRatioList(this.IncomeRatioList).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.IncomeDistributionDialogVisible = false;
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
      } else {
        this.$message.error("收益比例之和必须是100");
        // Show error message
      }
    },
    editIncomeDistribution() {
      this.editing = true;
      // Enable editing for IncomeDistribution column
      this.IncomeRatioList.forEach((row) => {
        console.log(row, "row");
        this.$set(row, "editable", true);
      });
    },
    //校验比例之和
    validateIncomeDistribution() {
      const sum = this.IncomeRatioList.reduce((acc, curr) => {
        return acc + parseFloat(curr.ratio || 0);
      }, 0);

      console.log(sum, "sum");
      return sum === 100;
    },
    getList() {
      if (this.dataForm.identification === "") {
        this.dataForm.identification = null;
      }
      this.dataForm.owner = this.userInfo.id;
      getEquipmentList({ params: { ...this.dataForm, ...this.pageData } }).then(
        (data) => {
          if (data.data.code === 200) {
            this.tableData = data.data.data;
            this.total = data.data.total || 0;
          } else {
            this.$message.error(data.data.message);
          }
        }
      );
    },
    //选择页码回调函数
    handlePage(pageNum) {
      console.log(pageNum, "pageNum");
      this.pageData.pageNum = pageNum;
      this.getList();
    },
    onSubmit() {
      console.log(this.searchAddr, "searchAddr");
      this.pageData.pageNum = 1;
      this.currentPage = 1;
      this.getList();
    },
    // 点击新增按钮
    submit() {
      console.log(this.form, "form");
      this.$refs.form.validate((valid) => {
        if (valid) {
          //产品口味
          //   this.form.productTaste =
          //     this.form.productTaste == []
          //       ? ""
          //       : this.form.productTaste.join(",");
          //   //产品规格
          //   this.form.juiceSize =
          //     this.form.juiceSize == [] ? "" : this.form.juiceSize.join(",");
          console.log(this.modalType, "modalType");
          if (this.modalType === 0) {
            addEquipment(this.form).then((response) => {
              if (response.data.code === 200) {
                this.getList();
                //清空表单数据
                this.$refs.form.resetFields();
                //关闭弹窗
                this.dialogVisible = false;
              } else {
                this.$message({
                  type: "error",
                  message: response.data.message,
                });
              }
            });
          } else if (this.modalType === 1) {
            editEquipment(this.form).then((response) => {
              if (response.data.code === 200) {
                //清空表单数据
                this.$refs.form.resetFields();
                //关闭弹窗
                this.dialogVisible = false;
                this.getList();
              } else {
                this.$message({
                  type: "error",
                  message: response.data.message,
                });
              }
            });
          }
        } else {
          this.$message({
            type: "error",
            message: "请填写完整信息",
          });
          return false;
        }
      });
    },
    //弹窗关闭
    handleClose() {
      this.$refs.form.resetFields();
      // 手动关闭对话框
      this.dialogVisible = false;
    },
    //收益分配弹窗关闭
    IncomeDistributionHandleClose() {
      this.$refs.form.resetFields();
      // 手动关闭对话框
      this.IncomeDistributionDialogVisible = false;
    },
    //收益分配按钮
    IncomeDistribution(row) {
      (this.IncomeDistributionDialogVisible = true),
        //需要对当前行数据进行深拷贝,否则会出错
        (this.form = JSON.parse(JSON.stringify(row)));
      console.log(this.form, "this.form");
      //根据机器id获取当前机器的收益比例
      getIncomeRatioListByEquipmentId({ params: { equipmentId: row.id } }).then(
        (res) => {
          if (res.data.code === 200) {
            this.IncomeRatioList = res.data.data;
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        }
      );
    },
    // 产品分配按钮
    productAlot(row) {
      (this.productAlotDialog = true),
        //需要对当前行数据进行深拷贝,否则会出错
        (this.form = JSON.parse(JSON.stringify(row)));
      console.log(this.form, "this.form");
      //获取当前设备配置的产品信息
      selEquipmentProductList({ params: { equipmentId: row.id } }).then(
        (res) => {
          if (res.data.code === 200) {
            this.equipmentProductList = res.data.data;
            this.equipmentProductList.forEach((row) => {
              console.log(row, "row");
              this.$set(row, "editing", false);
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        }
      );
    },
    //更新产品上下架
    productUpOrDown(row) {
      //更新分账开关
      updateEquipentProduct(row).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            type: "success",
            message: "更新成功",
          });
          //刷新产品配置列表
          this.productAlot(this.form);
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    //产品修改
    productEdit(row) {
      console.log(row, "row");
      row.editing = true;
    },
    //产品保存
    productSave(row) {
      console.log(row, "row");
      row.editing = false;
      updateEquipentProduct(row).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            type: "success",
            message: "更新成功",
          });
          //刷新产品配置列表
          this.productAlot(this.form);
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    //删除产品
    productDelete(row) {
      console.log(row, "row");
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delEquipmentProduct({ params: { id: row.id } })
          .then((response) => {
            if (response.data.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              //刷新产品配置列表
              this.productAlot(this.form);
            } else {
              this.$message({
                type: "error",
                message: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
          });
      });
    },
    //给设备添加产品 获取产品列表
    addNewProduct() {
      console.log("addProductDialogVisible", this.addProductDialogVisible);
      this.addProductDialogVisible = true;
      console.log("addProductDialogVisible", this.addProductDialogVisible);
      getProductList({
        params: {
          status: 1,
          pageNum: 1,
          pageSize: 10,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.productList = res.data.data;
          this.addProductListTotal = res.data.total;
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },
    productDialogClose() {
      this.addProductDialogVisible = false;
      // Reset search form
      this.searchProductForm = {
        productType: "",
        flavors: "",
        size: "",
        pageNum: 1,
        pageSize: 10,
      };
      // Clear product list
      this.productList = [];
      this.addProductListTotal = 0;
    },
    //产品类型筛选产品列表
    searchAddProductList() {
      getProductList({
        params: {
          status: 1,
          type: this.searchProductForm.productType,
          flavors: this.searchProductForm.flavors,
          size: this.searchProductForm.size,
          pageNum: this.searchProductForm.pageNum,
          pageSize: this.searchProductForm.pageSize,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.productList = res.data.data;
          this.addProductListTotal = res.data.total;
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },
    handleProductListPage(pageNum) {
      console.log(pageNum, "pageNum");
      this.searchProductForm.pageNum = pageNum;
      this.searchAddProductList();
    },
    productAlotDialogClose() {
      this.$refs.form.resetFields();
      // 手动关闭对话框
      this.productAlotDialog = false;
    },
    addProductClick(row) {
      //添加当前内容到产品配置列表,关闭选择产品的dialog
      //检查选择的产品是否已经添加 this.equipmentProductList
      let isExist = this.equipmentProductList.some((item) => {
        return item.productId == row.id;
      });
      if (isExist) {
        this.$message({
          type: "error",
          message: "该产品已经添加",
        });
        return;
      }
      //获取equipmentProductList 中最大的sort值
      let maxSort = 0;
      this.equipmentProductList.forEach((item) => {
        if (item.sort > maxSort) {
          maxSort = item.sort;
        }
      });
      this.equipmentProductList.push({
        equipmentId: this.form.id,
        productId: row.id,
        productType: row.type,
        productTypeName: row.productType,
        flavors: row.flavors,
        flavorsName: row.flavorName,
        size: row.size,
        sizeName: row.sizeName,
        price: row.price,
        name: row.name,
        img: row.img,
        status: 1,
        editing: false,
        sort: maxSort + 1,
        addTime: new Date().getTime(),
      });
      //保存到数据库
      addEquipmentProduct({
        equipmentId: this.form.id,
        productId: row.id,
        productType: row.type,
        flavors: row.flavors,
        size: row.size,
        price: row.price,
        sort: maxSort + 1,
        name: row.name,
        status: 1,
        addTime: new Date().getTime(),
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            type: "success",
            message: "添加成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
      this.addProductDialogVisible = false;
    },
    //产品配置排序
    moveUp(index) {
      if (index > 0) {
        // 如果当前元素不是第一个
        const temp = this.equipmentProductList[index]; // 将当前元素保存到临时变量中
        this.$set(
          this.equipmentProductList,
          index,
          this.equipmentProductList[index - 1]
        ); // 将当前元素替换为上一个元素
        this.$set(this.equipmentProductList, index - 1, temp); // 将上一个元素替换为当前元素
        //移动的时候,将俩个元素的sort值互换 用临时值,不然会出错
        let tempSort = this.equipmentProductList[index].sort;
        this.equipmentProductList[index].sort =
          this.equipmentProductList[index - 1].sort;
        this.equipmentProductList[index - 1].sort = tempSort;
      }
      //将修改后的俩个元素保存
      //   console.log(
      //     this.equipmentProductList[index].id,
      //     "this.equipmentProductList[index]"
      //   );
      //   console.log(this.equipmentProductList[index].sort);
      //   console.log(
      //     this.equipmentProductList[index - 1].id,
      //     "this.equipmentProductList[index - 1]"
      //   );
      console.log(this.equipmentProductList[index - 1].sort);
      updateEquipentProduct(this.equipmentProductList[index]);
      updateEquipentProduct(this.equipmentProductList[index - 1]);
    },
    moveDown(index) {
      if (index < this.equipmentProductList.length - 1) {
        // 如果当前元素不是最后一个
        const temp = this.equipmentProductList[index]; // 将当前元素保存到临时变量中
        this.$set(
          this.equipmentProductList,
          index,
          this.equipmentProductList[index + 1]
        ); // 将当前元素替换为下一个元素
        this.$set(this.equipmentProductList, index + 1, temp); // 将下一个元素替换为当前元素
        //移动的时候,将俩个元素的sort值互换 用临时值,不然会出错
        let tempSort = this.equipmentProductList[index].sort;
        this.equipmentProductList[index].sort =
          this.equipmentProductList[index + 1].sort;
        this.equipmentProductList[index + 1].sort = tempSort;
      }
      //将修改后的俩个元素保存
      //   console.log(
      //     this.equipmentProductList[index].id,
      //     "this.equipmentProductList[index]"
      //   );
      //   console.log(this.equipmentProductList[index].sort);
      //   console.log(
      //     this.equipmentProductList[index + 1].id,
      //     "this.equipmentProductList[index + 1]"
      //   );
      console.log(this.equipmentProductList[index + 1].sort);
      updateEquipentProduct(this.equipmentProductList[index]);
      updateEquipentProduct(this.equipmentProductList[index + 1]);
    },
    cancel() {
      this.handleClose();
      this.dialogVisible = false;
    },
    //点击编辑按钮
    handleEdit(row) {
      this.modalType = 1;
      this.dialogVisible = true;
      //需要对当前行数据进行深拷贝,否则会出错
      this.form = JSON.parse(JSON.stringify(row));
      console.log(this.form, "this.form");
      //格式化产品分类信息
      //   this.form.productTaste = this.form.productTaste.split(",").map(Number);
      //   this.form.juiceSize = this.form.juiceSize.split(",").map(Number);
      //获取省级信息
      getAreaList({ params: { level: 1 } }).then((res) => {
        console.log(res, "res");
        if (res.data.code === 200) {
          this.provinceList = res.data.data;
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
      //市级
      getAreaList({ params: { level: 2, pid: this.form.province } }).then(
        (res) => {
          console.log(res, "res");
          if (res.data.code === 200) {
            this.cityList = res.data.data;
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        }
      );
      //地区
      getAreaList({ params: { level: 3, pid: this.form.city } }).then((res) => {
        console.log(res, "res");
        if (res.data.code === 200) {
          this.areaList = res.data.data;
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    //点击删除按钮
    handleDelete(row) {
      const equipmentId = row.id;
      const params = qs.stringify({ id: equipmentId });
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delEquipment("/web/equipment/delEquipment", params)
          .then((response) => {
            if (response.data.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            } else {
              this.$message({
                type: "error",
                message: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
          });
      });
    },
    handleAdd() {
      this.form = {};
      this.dialogVisible = true;
      this.modalType = 0;
      //获取省级信息
      getAreaList({ params: { level: 1 } }).then((res) => {
        console.log(res, "res");
        if (res.data.code === 200) {
          this.provinceList = res.data.data;
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },

    //获取产品大小规格信息
    getJuiceSize(type, juiceSize) {
      if (!juiceSize) {
        return "";
      }
      const sizes = juiceSize.split(",");
      //获取 sizes 长度
      console.log(sizes.length, "sizes-size");

      if (type == 1) {
        const sizeNames = sizes.map((item) => this.juiceSizeMap()[item]);
        return sizes.length == 1 ? sizeNames.join("") : sizeNames.join(",");
      } else {
        const sizeNames = sizes.map((item) => this.iceCreamSizeMap()[item]);
        return sizes.length == 1 ? sizeNames.join("") : sizeNames.join(",");
      }
    },
    userClear() {
      this.dataForm.searchUserId = null;
    },
    //获取产品类型
    getProductType(type) {
      //根据 typeList 动态获取
      let typeList = this.typeList;
      let typeObj = typeList.find((item) => {
        return item.value == type;
      });
      return typeObj ? typeObj.label : "";
    },
    updateSystemSwitch(row) {
      //更新分账开关
      editEquipment(row).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            type: "success",
            message: "更新成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    // 修改用户选择处理方法
    handleUserSelect(user) {
      if (user) {
        // 判断当前是哪个场景在使用用户选择
        const currentDialog = this.dialogVisible
          ? "device"
          : this.IncomeDistributionDialogVisible
          ? "income"
          : "search";

        if (currentDialog === "search") {
          // 处理搜索条件中的用户选择
          this.dataForm.searchUserName = user.name;
          this.dataForm.searchUserId = user.id;
        } else if (currentDialog === "device") {
          // 处理设备修改的用户选择
          this.form.owner = user.id;
          this.form.ownerName = user.name;
        } else {
          // 处理收益分配的用户选择
          let isExist = this.IncomeRatioList.some((item) => {
            return item.userId === user.id;
          });

          if (isExist) {
            this.$message({
              type: "error",
              message: "该商家已经添加",
            });
            return;
          }

          this.IncomeRatioList.push({
            userName: user.name,
            userId: user.id,
            ratio: "",
            editable: true,
          });
        }
      }
      this.$store.commit("updateUserListDialogVisibility", false);
    },
    // 打开搜索用户选择对话框
    openSearchUserDialog() {
      this.$store.commit("updateUserListDialogVisibility", true);
    },

    // 清空搜索用户
    clearSearchUser() {
      this.dataForm.searchUserName = "";
      this.dataForm.searchUserId = null;
    },
  },
  mounted() {
    //进入页面刷新列表
    this.getList();
    //获取用户列表
    getUser().then((res) => {
      if (res.data.code == 200) {
        this.userList = res.data.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      } else {
        this.$message({
          message: res.data.message,
          type: "error",
        });
      }
    });
    //获取产品类型
    getProductCateList().then((res) => {
      if (res.data.code === 200) {
        this.typeList = res.data.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      } else {
        this.$message({
          message: "获取产品分类信息失败",
          type: "error",
        });
      }
      console.log("typeList", this.typeList);
    });
  },
  watch: {
    //监听form表单设备类型,当选中果汁时,清空冰激凌分类信息,选中冰激凌时,清空果汁分类信息
    "searchProductForm.productType"(val) {
      this.form.productTaste = [];
      //val 有值再查询
      if (val) {
        //根据产品类型获取产品口味
        getProductList({ params: { type: val, status: 1 } }).then((res) => {
          if (res.data.code === 200) {
            this.productTasteList = res.data.data.map((item) => {
              return {
                value: item.flavors,
                label: item.flavorName,
              };
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      }
    },
    "form.province": {
      handler: function (newVal) {
        console.log(newVal, "newVal");
        getAreaList({ params: { level: 2, pid: newVal } }).then((res) => {
          console.log(res, "res");
          if (res.data.code === 200) {
            //清空市级信息
            this.cityList = res.data.data;
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
      },
    },
    "form.city": {
      handler: function (newVal) {
        console.log(newVal, "newVal");
        getAreaList({ params: { level: 3, pid: newVal } }).then((res) => {
          console.log(res, "res");
          if (res.data.code === 200) {
            this.areaList = res.data.data;
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
      },
    },
  },
  //获取localsession
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(this.userInfo, "userInfo");
  },
};
</script>
<style lang="less" scoped>
.manage {
  height: 100%;
  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .form-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;

    .el-form-item {
      margin-bottom: 0;
      flex: 1;
      min-width: 300px;

      /deep/ .el-form-item__content {
        width: calc(100% - 100px);

        .el-input,
        .el-select {
          width: 100%;
        }

        .merchant-input {
          .el-input__icon {
            line-height: 32px;
          }
          .el-input-group__append {
            padding: 0;
            .el-button {
              margin: 0;
              border: none;
              height: 30px;
              padding: 7px 15px;
            }
          }
        }
      }
    }
  }

  .common-table {
    position: relative;
    margin-top: 20px;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
  }
  .operation-buttons {
    display: flex;
    align-items: center;
    gap: 8px;

    .el-button {
      margin: 0;
    }

    .el-dropdown {
      margin: 0;

      .el-button {
        margin: 0;
      }
    }
  }
}
</style>
