<template>
  <div class="opinion-list">
    <!-- 搜索区域 -->
    <div class="search-area">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="反馈类型">
          <el-select
            v-model="searchForm.type"
            placeholder="请选择反馈类型"
            clearable
          >
            <el-option label="产品问题" :value="1" />
            <el-option label="页面问题" :value="2" />
            <el-option label="功能建议" :value="3" />
            <el-option label="其他" :value="4" />
          </el-select>
        </el-form-item>
        <el-form-item label="反馈内容">
          <el-input
            v-model="searchForm.opinionContent"
            placeholder="请输入反馈内容"
            clearable
          />
        </el-form-item>
        <el-form-item label="发起人">
          <el-input
            v-model="searchForm.initiatorName"
            placeholder="请选择发起人"
            :readonly="true"
            clearable
            @click.native="openUserDialog"
          >
            <template slot="suffix">
              <i
                v-if="searchForm.initiatorName"
                class="el-icon-circle-close el-input__icon"
                @click.stop="clearInitiator"
                style="cursor: pointer"
              ></i>
            </template>
            <template slot="append">
              <el-button
                icon="el-icon-user"
                @click.stop="openUserDialog"
              ></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="处理状态">
          <el-select
            v-model="searchForm.status"
            placeholder="请选择处理状态"
            clearable
          >
            <el-option label="未处理" :value="0" />
            <el-option label="已处理" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item label="反馈时间">
          <el-date-picker
            v-model="searchForm.timeRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
          <el-button @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 反馈列表 -->
    <el-table :data="tableData" style="width: 100%" v-loading="loading">
      <el-table-column type="index" label="序号" width="80" align="center" />
      <el-table-column prop="type" label="反馈类型" width="120" align="center">
        <template #default="scope">
          {{ getTypeText(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="opinionContent"
        label="反馈内容"
        min-width="200"
        show-overflow-tooltip
      />
      <el-table-column
        prop="initiatorName"
        label="发起人"
        width="120"
        align="center"
      />
      <el-table-column
        prop="status"
        label="处理状态"
        width="100"
        align="center"
      >
        <template #default="scope">
          <el-tag :type="scope.row.status === 1 ? 'success' : 'warning'">
            {{ scope.row.status === 1 ? "已处理" : "未处理" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="addTime"
        label="反馈时间"
        width="160"
        align="center"
      />
      <el-table-column
        prop="updateTime"
        label="更新时间"
        width="160"
        align="center"
      />
      <el-table-column label="操作" width="120" align="center" fixed="right">
        <template #default="scope">
          <el-button
            type="primary"
            size="mini"
            @click="handleOpinion(scope.row)"
            :disabled="scope.row.status === 1"
          >
            处理
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="pagination-container">
      <el-pagination
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="page.total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 处理对话框 -->
    <el-dialog title="处理反馈" :visible.sync="dialogVisible" width="500px">
      <el-form
        ref="opinionForm"
        :model="formData"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="反馈类型">
          <el-input :value="getTypeText(formData.type)" disabled />
        </el-form-item>
        <el-form-item label="反馈内容">
          <el-input
            v-model="formData.opinionContent"
            type="textarea"
            :rows="3"
            disabled
          />
        </el-form-item>
        <el-form-item label="发起人">
          <el-input v-model="formData.initiatorName" disabled />
        </el-form-item>
        <el-form-item label="处理备注" prop="remark">
          <el-input
            v-model="formData.remark"
            type="textarea"
            :rows="3"
            placeholder="请输入处理备注"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 添加用户选择对话框 -->
    <user-list-dialog
      :dialogVisible="UserListDialogVisible"
      :user="user"
      @chooseUser="handleUserSelect"
    />
  </div>
</template>

<script>
import { getOpinionList, handleOpinion } from "@/api";
import UserListDialog from "@/components/UserListDialog.vue";

export default {
  name: "OpinionList",
  components: {
    UserListDialog,
  },
  computed: {
    UserListDialogVisible() {
      return this.$store.state.UserListDialogVisible;
    },
  },
  data() {
    return {
      // 搜索表单
      searchForm: {
        type: "",
        opinionContent: "",
        initiatorName: "",
        initiatorId: "",
        status: "",
        timeRange: [],
      },
      // 日期选择器配置
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 表格数据
      tableData: [],
      loading: false,
      // 分页信息
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 对话框
      dialogVisible: false,
      formData: {
        id: null,
        type: null,
        opinionContent: "",
        initiatorName: "",
        status: 0,
        remark: "",
      },
      // 表单验证规则
      rules: {
        remark: [
          { required: true, message: "请输入处理备注", trigger: "blur" },
          { max: 500, message: "长度不能超过500个字符", trigger: "blur" },
        ],
      },
      // 反馈类型映射
      typeMap: {
        1: "产品问题",
        2: "页面问题",
        3: "功能建议",
        4: "其他",
      },
      user: [], // 添加 user 数组
    };
  },
  created() {
    this.initData();
  },
  beforeDestroy() {
    // 清理组件状态
    this.clearComponentState();
  },
  methods: {
    // 初始化数据
    initData() {
      this.searchForm = {
        type: "",
        opinionContent: "",
        initiatorName: "",
        initiatorId: "",
        status: "",
        timeRange: [],
      };
      this.page = {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      };
      this.tableData = [];
      this.loading = false;
      this.dialogVisible = false;
      this.getList();
    },

    // 清理组件状态
    clearComponentState() {
      this.searchForm = {
        type: "",
        opinionContent: "",
        initiatorName: "",
        initiatorId: "",
        status: "",
        timeRange: [],
      };
      this.tableData = [];
      this.loading = false;
      this.dialogVisible = false;
      this.$store.commit("updateUserListDialogVisibility", false);
    },

    // 获取列表数据
    async getList() {
      if (this.loading) return; // 防止重复请求

      this.loading = true;
      try {
        const params = {
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,
        };

        // 只添加有值的搜索条件
        if (this.searchForm.type) params.type = this.searchForm.type;
        if (this.searchForm.opinionContent)
          params.opinionContent = this.searchForm.opinionContent;
        if (this.searchForm.initiatorId)
          params.initiatorId = this.searchForm.initiatorId;
        if (this.searchForm.status !== "")
          params.status = this.searchForm.status;
        if (
          this.searchForm.timeRange &&
          this.searchForm.timeRange.length === 2
        ) {
          params.addTimeStart = this.searchForm.timeRange[0];
          params.addTimeEnd = this.searchForm.timeRange[1];
        }

        const res = await getOpinionList({ params });
        if (res.data.code === 200) {
          this.tableData = res.data.data || [];
          this.page.total = res.data.total || 0;
        } else {
          this.$message.error(res.data.msg || "获取反馈列表失败");
        }
      } catch (error) {
        console.error("获取反馈列表失败:", error);
        this.$message.error("获取反馈列表失败");
      } finally {
        this.loading = false;
      }
    },

    // 获取反馈类型文本
    getTypeText(type) {
      return this.typeMap[type] || "未知类型";
    },

    // 搜索
    handleSearch() {
      this.page.pageNum = 1;
      this.getList();
    },

    // 重置搜索
    resetSearch() {
      this.searchForm = {
        type: "",
        opinionContent: "",
        initiatorName: "",
        initiatorId: "",
        status: "",
        timeRange: [],
      };
      this.page.pageNum = 1;
      this.getList();
    },

    // 处理分页大小变化
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    },

    // 处理页码变化
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },

    // 处理反馈
    handleOpinion(row) {
      this.formData = {
        id: row.id,
        type: row.type,
        opinionContent: row.opinionContent,
        initiatorName: row.initiatorName,
        status: 1,
        remark: "",
      };
      this.dialogVisible = true;
    },

    // 提交处理
    submitForm() {
      this.$refs.opinionForm.validate(async (valid) => {
        if (valid) {
          try {
            const params = {
              id: this.formData.id,
              status: 1,
              remark: this.formData.remark,
            };
            const res = await handleOpinion(params);
            if (res.data.code === 200) {
              this.$message.success("处理成功");
              this.dialogVisible = false;
              this.getList();
            } else {
              this.$message.error(res.data.msg || "处理失败");
            }
          } catch (error) {
            console.error("处理失败:", error);
            this.$message.error("处理失败");
          }
        }
      });
    },

    // 修改打开用户选择对话框方法
    openUserDialog(e) {
      e && e.stopPropagation();
      this.$store.commit("updateUserListDialogVisibility", true);
    },

    // 修改用户选择处理方法
    handleUserSelect(user) {
      if (user) {
        this.searchForm.initiatorName = user.name;
        this.searchForm.initiatorId = user.id;
      }
      this.$store.commit("updateUserListDialogVisibility", false);
    },

    // 清空发起人
    clearInitiator() {
      this.searchForm.initiatorName = "";
      this.searchForm.initiatorId = "";
    },
  },
};
</script>

<style scoped>
.opinion-list {
  padding: 20px;
}

.search-area {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

/* 响应式布局 */
@media screen and (max-width: 768px) {
  .search-form {
    flex-direction: column;
  }

  .el-form-item {
    margin-right: 0;
    width: 100%;
  }
}

/* 添加用户选择输入框样式 */
.el-input.is-readonly .el-input__inner {
  cursor: pointer;
  background-color: #fff;
}

.el-input-group__append {
  cursor: pointer;
}
</style>
