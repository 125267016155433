export default {
    state: {
      isCollapse: false,
      tabsList: [
        {
          path: "/",
          name: "home",
          label: "首页",
          icon: "s-home",
          url: "Home/Home",
        }
      ],
      menu: []
    },
    mutations: {
      collapseMenu(state) {
        state.isCollapse = !state.isCollapse;
      },
      selectMenu(state, val) {
        if (val.name !== 'home') {
          const index = state.tabsList.findIndex(item => item.name === val.name);
          if (index === -1) {
            state.tabsList.push(val);
          }
        }
      },
      closeTag(state, item) {
        const index = state.tabsList.findIndex(val => val.name === item.name);
        state.tabsList.splice(index, 1);
      },
      setMenu(state, val) {
        state.menu = val;
        window.localStorage.setItem('menu', JSON.stringify(val));
      },
      // 动态注册路由
      addMenu(state, router) {
        // 1. 检查菜单数据
        const menuStr = window.localStorage.getItem('menu');
        if (!menuStr) {
          console.warn('No menu data found in localStorage');
          return;
        }

        try {
          // 2. 解析菜单数据
          const menu = JSON.parse(menuStr);
          console.log('Parsed menu data:', menu);
          if (!Array.isArray(menu)) {
            console.error('Invalid menu data format');
            return;
          }
          state.menu = menu;

          // 存储路由权限映射
          const routePermissions = {};

          // 3. 处理路由数据
          const processRoute = (route) => {
            console.log('Processing route:', route);

            // 保存该路由的权限信息
            if (route.buttonList) {
              routePermissions[route.path] = route.buttonList;
            }

            const processedRoute = {
              path: route.path,
              name: route.name,
              component: () => import(`@/views/${route.url}`),
              meta: {
                title: route.label || route.name,
                permissions: route.buttonList || []
              }
            };

            console.log('Final processed route:', processedRoute);
            return processedRoute;
          };

          // 4. 生成路由配置
          const routes = menu.reduce((acc, item) => {
            if (Array.isArray(item.children) && item.children.length > 0) {
              const children = item.children.map(child => processRoute(child));
              acc.push(...children);
            } else {
              acc.push(processRoute(item));
            }
            return acc;
          }, []);

          // 5. 注册路由
          console.log('Registering routes:', routes);
          routes.forEach(route => {
            router.addRoute('Main', route);
          });

          // 6. 保存权限映射到 localStorage
          window.localStorage.setItem('routePermissions', JSON.stringify(routePermissions));

          // 7. 刷新当前路由
          const currentRoute = router.currentRoute;
          if (currentRoute) {
            router.replace({
              path: currentRoute.path,
              query: currentRoute.query,
              params: currentRoute.params
            });
          }

          console.log('Routes registered successfully');
        } catch (error) {
          console.error('Error processing menu data:', error);
        }
      }
    }
  };
