import router from "@/router";
import axios from "axios";
import Cookies from 'js-cookie';

// 根据环境和模式设置 baseURL
const getBaseURL = () => {
    if (process.env.NODE_ENV === 'development') {
        return process.env.VUE_APP_URL;  // 开发环境使用本地服务器
    }
    // 生产环境但在本地运行时使用代理
    if (window.location.hostname === 'localhost') {
        return '/api';
    }
    // 实际生产环境
    return process.env.VUE_APP_URL;
};

// 创建 axios 实例
const http = axios.create({
    baseURL: getBaseURL(),
    timeout: 30000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

// 请求拦截器
http.interceptors.request.use(
    config => {
        // 开发环境下打印请求信息
        if (process.env.NODE_ENV === 'development' || window.location.hostname === 'localhost') {
            console.log('Request:', {
                url: config.url,
                baseURL: config.baseURL,
                method: config.method,
                env: process.env.NODE_ENV
            });
        }
        
        const token = Cookies.get('token')
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
);

// 响应拦截器
http.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    router.push({ name: 'login' }).catch(err => {
                        console.error('Navigation error:', err)
                    });
                    break;
                case 403:
                    console.error('没有权限访问');
                    break;
                case 404:
                    console.error('请求的资源不存在');
                    break;
                case 500:
                    console.error('服务器错误');
                    break;
                default:
                    console.error('请求失败:', error.response.status);
            }
        } else if (error.request) {
            console.error('网络错误，请检查您的网络连接');
            console.error('详细错误:', error);
        } else {
            console.error('请求配置错误:', error.message);
        }
        return Promise.reject(error);
    }
);

export default http;
