<template>
  <div class="system-log">
    <div class="search-area">
      <!-- 搜索区域 -->
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="模块名称">
          <el-input
            v-model="searchForm.module"
            placeholder="请输入模块名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="接口描述">
          <el-input
            v-model="searchForm.description"
            placeholder="请输入接口描述"
            clearable
          />
        </el-form-item>
        <el-form-item label="请求URL">
          <el-input
            v-model="searchForm.requestUrl"
            placeholder="请输入请求URL"
            clearable
          />
        </el-form-item>
        <el-form-item label="方法名称">
          <el-input
            v-model="searchForm.methodName"
            placeholder="请输入方法名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="处理时间">
          <el-select
            v-model="searchForm.processTime"
            placeholder="请选择处理时间范围"
          >
            <el-option label="0-100MS" value="1" />
            <el-option label="100-1000MS" value="2" />
            <el-option label="大于1000MS" value="3" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
          <el-button @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 日志列表 -->
    <el-table :data="tableData" style="width: 100%" v-loading="loading">
      <el-table-column prop="module" label="模块名称" show-overflow-tooltip />
      <el-table-column prop="description" label="描述" show-overflow-tooltip />
      <el-table-column
        prop="requestUrl"
        label="请求URL"
        show-overflow-tooltip
      />
      <el-table-column
        prop="methodName"
        label="方法名称"
        show-overflow-tooltip
      />
      <el-table-column
        prop="requestParams"
        label="请求参数"
        show-overflow-tooltip
      >
        <template #default="scope">
          <el-tooltip effect="dark" placement="top">
            <template #content>
              <pre>{{ scope.row.requestParams }}</pre>
            </template>
            <span>{{ scope.row.requestParams }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="responseResult"
        label="响应结果"
        show-overflow-tooltip
      >
        <template #default="scope">
          <el-tooltip effect="dark" placement="top">
            <template #content>
              <pre>{{ scope.row.responseResult }}</pre>
            </template>
            <span>{{ scope.row.responseResult }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="processTime" label="处理时间(ms)" width="120">
        <template #default="scope">
          <span :class="getProcessTimeClass(scope.row.processTime)">
            {{ scope.row.processTime }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="errorMessage"
        label="错误信息"
        show-overflow-tooltip
      />
      <el-table-column prop="createTime" label="添加时间" width="160" />
    </el-table>

    <!-- 分页 -->
    <div class="pagination-container">
      <el-pagination
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="page.total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { getSystemLogList } from "../../api";
export default {
  name: "SystemLog",
  data() {
    return {
      // 搜索表单数据
      searchForm: {
        module: "",
        description: "",
        requestUrl: "",
        methodName: "",
        processTime: "",
      },
      // 表格数据
      tableData: [],
      // 加载状态
      loading: false,
      // 分页信息
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表数据
    async getList() {
      this.loading = true;
      try {
        const params = {
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,
        };

        // Add searchForm parameters only if they are not null
        for (const key in this.searchForm) {
          if (this.searchForm[key] !== null && this.searchForm[key] !== "") {
            params[key] = this.searchForm[key];
          }
        }

        const res = await getSystemLogList({ params });
        if (res.data.code === 200) {
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
        } else {
          this.$message.error(res.msg || "获取日志列表失败");
        }
      } catch (error) {
        console.error("获取日志列表失败:", error);
        this.$message.error("获取日志列表失败");
      } finally {
        this.loading = false;
      }
    },

    // 搜索
    handleSearch() {
      this.page.pageNum = 1;
      this.getList();
    },

    // 重置搜索
    resetSearch() {
      this.searchForm = {
        module: null,
        description: null,
        requestUrl: null,
        methodName: null,
        processTime: null,
      };
      this.page.pageNum = 1;
      this.getList();
    },

    // 处理分页大小变化
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    },

    // 处理页码变化
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },

    // 处理时间样式
    getProcessTimeClass(time) {
      if (time <= 100) return "process-time-normal";
      if (time <= 1000) return "process-time-warning";
      return "process-time-danger";
    },
  },
};
</script>

<style scoped>
.system-log {
  padding: 20px;
}

.search-area {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
}

.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.process-time-normal {
  color: #67c23a;
}

.process-time-warning {
  color: #e6a23c;
}

.process-time-danger {
  color: #f56c6c;
}

/* 响应式布局 */
@media screen and (max-width: 768px) {
  .search-form {
    flex-direction: column;
  }

  .el-form-item {
    margin-right: 0;
    width: 100%;
  }
}
</style>
